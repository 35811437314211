import { useState } from "react";
import Head from "next/head";
import * as jwt from "jsonwebtoken";
import * as Sentry from "@sentry/react";
import { NextPageContext } from "next";

import ConnectWalletAndEmailModal from "@/components/ConnectWalletAndEmailModal/ConnectWalletAndEmailModal";
import LoadingBar from "@/components/LoadingBar/LoadingBar";
import Header from "@/components/Header/Header";
import { useAppContext } from "@/state/Context";
import AppHome from "@/components/AppHome/AppHome";
import LandingPage from "@/components/LandingPage/LandingPage";
import { JWTVerify } from "@/types";
import useUpdatePageLoader from "@/hooks/useUpdatePageLoader";

interface IProps {
  walletAddress: string;
}

export default function Home({ walletAddress: walletAddressProps }: IProps) {
  const { walletAddress: walletAddressState } = useAppContext();

  const [isOpen, setIsOpen] = useState(false);

  const walletAddress = walletAddressState || walletAddressProps;

  useUpdatePageLoader({ walletAddress });

  return (
    <>
      <Head>
        <title>POKE - Home</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <LoadingBar />
      <Header walletAddress={walletAddress} />
      {walletAddress?.length > 0 ? (
        <AppHome />
      ) : (
        <LandingPage setIsOpen={setIsOpen} />
      )}
      <ConnectWalletAndEmailModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
}

export async function getServerSideProps(context: NextPageContext) {
  const cookie = context.req?.headers.cookie;
  const parsedCookie = cookie?.replace("accessToken=", "");

  if (parsedCookie) {
    try {
      const data = jwt.verify(
        parsedCookie,
        process.env.SB_JWT_SECRET || ""
      ) as JWTVerify;

      if (data.user_metadata?.walletAddress) {
        return { props: { walletAddress: data.user_metadata.walletAddress } };
      }
    } catch (err) {
      Sentry.captureException(err);
      return { props: { walletAddress: "" } };
    }
  }
  return { props: { walletAddress: "" } };
}
