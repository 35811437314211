import styled from "styled-components";
import Link from "next/link";
import { animated } from "@react-spring/web";

import { PillLink } from "@/common/Links/Links";
import Spacer from "@/common/Spacer/Spacer";
import LandingPageContent from "@/components/LandingPageContent/LandingPageContent";
import { useAppContext } from "@/state/Context";
import useAnimation from "@/hooks/useAnimation";
import Text from "@/common/Text/Text";

export default function AppHome() {
  const { setPageLoaderStatus } = useAppContext();
  const { style, onMouseEnter } = useAnimation({ translateY: "8" });
  return (
    <LandingPageContainer>
      <HeroContainer>
        <UpperHero>
          <div>
            <Header>You&apos;re ready to POKE</Header>
            <Subheader>Go ahead and choose one of the following.</Subheader>
          </div>
          <LinkContainer>
            <Text fontWeight={600}>Now for the fun:</Text>
            <Spacer size={16} axis="y" />
            <Link href="/create" passHref>
              <PillLink
                size="large"
                href="/create"
                onClick={() => setPageLoaderStatus(10)}
                width="full"
              >
                Create a POKE
              </PillLink>
            </Link>
            <Spacer size={16} axis="y" />
            <Link href="/claim" passHref>
              <PillLink
                size="large"
                onClick={() => setPageLoaderStatus(10)}
                href="/claim"
                width="full"
              >
                Claim a POKE
              </PillLink>
            </Link>
          </LinkContainer>
        </UpperHero>
        <LowerHero>
          <ScrollLink href="#poke" onMouseEnter={onMouseEnter}>
            What&rsquo;s a POKE?
            <br />
            <animated.div style={style}>&#9660;</animated.div>
          </ScrollLink>
        </LowerHero>
      </HeroContainer>
      <Spacer size={32} axis="y" />
      <LandingPageContent />
    </LandingPageContainer>
  );
}

const LandingPageContainer = styled.div`
  max-width: 1124px;
  width: 95%;
  margin: 0 auto;
  padding: 0px 0;
`;

const HeroContainer = styled.div`
  min-height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 64px;
  @media (max-width: 850px) {
    padding: 0;
  }
`;

const UpperHero = styled.div`
  width: 100%;
  margin-top: -70px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 850px) {
    justify-content: center;
    flex-direction: column;
  }
`;

const LowerHero = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
`;

const Header = styled.p`
  font-size: var(--35px);
  font-family: var(--kotori);
  color: var(--poke);
  font-weight: 700;

  @media (max-width: 850px) {
    text-align: center;
    font-size: var(--32px);
  }
`;

const Subheader = styled.p`
  font-weight: 400;

  @media (max-width: 850px) {
    text-align: center;
    margin-bottom: 64px;
  }
`;

const LinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 258px;
`;

const ScrollLink = styled.a`
  display: block;
  width: fit-content;
  margin: 20px auto;
  text-decoration: none;
  text-align: center;
  font-family: "Kotori Rose", Arial, sans-serif;
  font-weight: 700;
  color: var(--poke);
  padding: 0 20px;
  height: 55px;
`;
