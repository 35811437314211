import styled from "styled-components";
import { animated } from "@react-spring/web";

import Spacer from "@/common/Spacer/Spacer";
import LandingPageContent from "@/components/LandingPageContent/LandingPageContent";
import useAnimation from "@/hooks/useAnimation";
import { PillButton } from "@/common/Buttons/Buttons";
import LandingPageFlower from "@/common/Icons/LandingPageFlower";

interface IProps {
  setIsOpen: (value: boolean) => void;
}

export default function LandingPage({ setIsOpen }: IProps) {
  const { style, onMouseEnter } = useAnimation({ translateY: "8" });

  return (
    <LandingPageContainer>
      <HeroContainer>
        <UpperHero>
          <MainTextContainer>
            <Header>Proof your professional history with peers.</Header>
            <Spacer size={16} axis="y" />
            <Subheader>
              Socially-driven career tokens to share and credit your work within
              your community.
            </Subheader>
            <Spacer size={32} axis="y" />
            <ConnectButtonContainer>
              <PillButton width="full" onClick={() => setIsOpen(true)}>
                Connect
              </PillButton>
            </ConnectButtonContainer>
          </MainTextContainer>
          <Spacer size={64} axis="x" />
          <ConnectWalletContainer>
            <LandingPageFlower />
          </ConnectWalletContainer>
        </UpperHero>
        <LowerHero>
          <ScrollLink href="#poke" onMouseEnter={onMouseEnter}>
            What&rsquo;s a POKE?
            <br />
            <animated.div style={style}>&#9660;</animated.div>
          </ScrollLink>
        </LowerHero>
      </HeroContainer>
      <Spacer size={32} axis="y" />
      <LandingPageContent />
    </LandingPageContainer>
  );
}

const LandingPageContainer = styled.div`
  max-width: 1124px;
  width: 95%;
  margin: 0 auto;
  padding: 0px 0;
`;

const HeroContainer = styled.div`
  min-height: calc(100vh - 70px);
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UpperHero = styled.div`
  width: 100%;
  margin-top: -70px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1300px) {
    padding: 0 32px;
  }

  @media (max-width: 826px) {
    padding: 0 10%;
    justify-content: center;
    flex-direction: column;
  }
`;

const ConnectButtonContainer = styled.div`
  width: max-content;
  @media (max-width: 826px) {
    width: 100%;
    align-self: center;
    max-width: 400px;
  }
`;

const LowerHero = styled.div`
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
`;

const ScrollLink = styled.a`
  display: block;
  width: fit-content;
  margin: 20px auto;
  text-decoration: none;
  text-align: center;
  font-family: "Kotori Rose", Arial, sans-serif;
  color: var(--poke);
  padding: 0 20px;
  font-weight: 700;
  height: 55px;
`;

const MainTextContainer = styled.div`
  max-width: 500px;

  @media (max-width: 826px) {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
`;

const Header = styled.p`
  font-size: var(--35px);
  line-height: 1.22;
  color: var(--poke);
  font-family: var(--kotori);
  font-weight: 700;

  @media (max-width: 826px) {
    text-align: center;
    font-size: var(--32px);
  }
`;

const Subheader = styled.p`
  font-weight: 300;

  @media (max-width: 826px) {
    text-align: center;
    max-width: 300px;
    margin: auto;
  }
`;

const ConnectWalletContainer = styled.div`
  @media (max-width: 826px) {
    display: none;
  }
`;
