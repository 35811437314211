export default function LandingPageFlower() {
  return (
    <svg
      width="438"
      height="438"
      viewBox="0 0 438 438"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M219.003 65.6981C219.003 101.985 189.586 131.396 153.305 131.396C145.658 131.396 138.316 130.082 131.49 127.681C131.441 128.912 131.407 130.148 131.407 131.396C131.407 179.777 170.628 218.997 219.008 218.997C267.389 218.997 306.609 179.777 306.609 131.396C306.609 130.154 306.576 128.912 306.526 127.681C299.7 130.082 292.359 131.396 284.712 131.396C248.425 131.396 219.014 101.979 219.014 65.6981H219.003Z"
        fill="url(#paint0_linear_787_5918)"
      />
      <path
        d="M306.515 127.686C332.078 118.692 350.399 94.3384 350.399 65.6981C350.399 29.4166 320.988 0 284.701 0C256.066 0 231.712 18.3209 222.712 43.8837C268.138 45.7801 304.619 82.2612 306.515 127.686Z"
        fill="url(#paint1_linear_787_5918)"
      />
      <path
        d="M131.485 127.686C133.381 82.2612 169.862 45.7801 215.288 43.8837C206.288 18.3209 181.939 0 153.299 0C117.012 0 87.6011 29.4166 87.6011 65.6981C87.6011 94.3328 105.922 118.687 131.485 127.686Z"
        fill="url(#paint2_linear_787_5918)"
      />
      <path
        d="M219.003 372.302C219.003 336.015 248.419 306.604 284.701 306.604C292.347 306.604 299.689 307.918 306.515 310.319C306.565 309.088 306.598 307.852 306.598 306.604C306.598 258.223 267.378 219.003 218.997 219.003C170.616 219.003 131.396 258.223 131.396 306.604C131.396 307.846 131.429 309.088 131.479 310.319C138.305 307.918 145.647 306.604 153.294 306.604C189.58 306.604 218.992 336.021 218.992 372.302H219.003Z"
        fill="url(#paint3_linear_787_5918)"
      />
      <path
        d="M131.485 310.314C105.922 319.308 87.6011 343.662 87.6011 372.302C87.6011 408.589 117.018 438 153.299 438C181.934 438 206.288 419.679 215.288 394.116C169.862 392.22 133.381 355.739 131.485 310.314Z"
        fill="url(#paint4_linear_787_5918)"
      />
      <path
        d="M306.515 310.314C304.619 355.739 268.138 392.22 222.712 394.116C231.707 419.679 256.06 438 284.701 438C320.988 438 350.399 408.583 350.399 372.302C350.399 343.667 332.078 319.313 306.515 310.314Z"
        fill="url(#paint5_linear_787_5918)"
      />
      <path
        d="M65.698 219.003C101.985 219.003 131.396 248.419 131.396 284.701C131.396 292.347 130.082 299.689 127.681 306.515C128.912 306.565 130.148 306.598 131.396 306.598C179.777 306.598 218.997 267.378 218.997 218.997C218.997 170.616 179.777 131.396 131.396 131.396C130.154 131.396 128.912 131.429 127.681 131.479C130.082 138.305 131.396 145.647 131.396 153.294C131.396 189.581 101.979 218.992 65.698 218.992V219.003Z"
        fill="url(#paint6_linear_787_5918)"
      />
      <path
        d="M127.686 131.485C118.692 105.922 94.3384 87.6011 65.6981 87.6011C29.4166 87.6011 0 117.018 0 153.299C0 181.934 18.3209 206.288 43.8837 215.288C45.7801 169.862 82.2612 133.381 127.686 131.485Z"
        fill="url(#paint7_linear_787_5918)"
      />
      <path
        d="M127.686 306.515C82.2612 304.619 45.7801 268.138 43.8837 222.712C18.3209 231.712 0 256.066 0 284.701C0 320.988 29.4166 350.399 65.6981 350.399C94.3328 350.399 118.687 332.078 127.686 306.515Z"
        fill="url(#paint8_linear_787_5918)"
      />
      <path
        d="M372.302 219.003C336.015 219.003 306.604 189.586 306.604 153.305C306.604 145.658 307.918 138.316 310.319 131.49C309.088 131.441 307.851 131.407 306.604 131.407C258.223 131.407 219.003 170.628 219.003 219.008C219.003 267.389 258.223 306.609 306.604 306.609C307.846 306.609 309.088 306.576 310.319 306.526C307.918 299.7 306.604 292.359 306.604 284.712C306.604 248.425 336.02 219.014 372.302 219.014V219.003Z"
        fill="url(#paint9_linear_787_5918)"
      />
      <path
        d="M310.313 306.515C319.308 332.078 343.662 350.399 372.302 350.399C408.589 350.399 438 320.982 438 284.701C438 256.066 419.679 231.712 394.116 222.712C392.22 268.138 355.739 304.619 310.313 306.515Z"
        fill="url(#paint10_linear_787_5918)"
      />
      <path
        d="M310.313 131.485C355.739 133.381 392.22 169.862 394.116 215.288C419.679 206.293 438 181.94 438 153.299C438 117.012 408.583 87.6011 372.302 87.6011C343.667 87.6011 319.313 105.922 310.313 131.485Z"
        fill="url(#paint11_linear_787_5918)"
      />
      <circle cx="219.5" cy="219.5" r="68.5" fill="#FBFFF8" />
      <defs>
        <linearGradient
          id="paint0_linear_787_5918"
          x1="219.008"
          y1="65.6981"
          x2="219.008"
          y2="218.997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0F9C9" />
          <stop offset="1" stopColor="#8DE1D9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_787_5918"
          x1="286.556"
          y1="0"
          x2="286.556"
          y2="127.686"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0F9C9" />
          <stop offset="1" stopColor="#8DE1D9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_787_5918"
          x1="151.444"
          y1="0"
          x2="151.444"
          y2="127.686"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0F9C9" />
          <stop offset="1" stopColor="#8DE1D9" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_787_5918"
          x1="218.997"
          y1="219.003"
          x2="218.997"
          y2="372.302"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0F9C9" />
          <stop offset="1" stopColor="#8DE1D9" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_787_5918"
          x1="151.444"
          y1="310.314"
          x2="151.444"
          y2="438"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0F9C9" />
          <stop offset="1" stopColor="#8DE1D9" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_787_5918"
          x1="286.556"
          y1="310.314"
          x2="286.556"
          y2="438"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0F9C9" />
          <stop offset="1" stopColor="#8DE1D9" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_787_5918"
          x1="142.348"
          y1="131.396"
          x2="142.348"
          y2="306.598"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0F9C9" />
          <stop offset="1" stopColor="#8DE1D9" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_787_5918"
          x1="63.8432"
          y1="87.6011"
          x2="63.8432"
          y2="215.288"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0F9C9" />
          <stop offset="1" stopColor="#8DE1D9" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_787_5918"
          x1="63.8432"
          y1="222.712"
          x2="63.8432"
          y2="350.399"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0F9C9" />
          <stop offset="1" stopColor="#8DE1D9" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_787_5918"
          x1="295.652"
          y1="131.407"
          x2="295.652"
          y2="306.609"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0F9C9" />
          <stop offset="1" stopColor="#8DE1D9" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_787_5918"
          x1="374.157"
          y1="222.712"
          x2="374.157"
          y2="350.399"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0F9C9" />
          <stop offset="1" stopColor="#8DE1D9" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_787_5918"
          x1="374.157"
          y1="87.6011"
          x2="374.157"
          y2="215.288"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0F9C9" />
          <stop offset="1" stopColor="#8DE1D9" />
        </linearGradient>
      </defs>
    </svg>
  );
}
