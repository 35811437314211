import { useContext, useEffect, useRef } from "react";

import { useAppContext } from "@/state/Context";

interface IProps {
  walletAddress: string;
}

export default function useUpdatePageLoader({ walletAddress }: IProps) {
  const isUpdatedPageLoader = useRef(false);

  const { setWalletAddress, pageLoaderStatus, setPageLoaderStatus } =
    useAppContext();

  useEffect(() => {
    setWalletAddress(walletAddress);
  }, [walletAddress, setWalletAddress]);

  useEffect(() => {
    if (pageLoaderStatus !== 0 && !isUpdatedPageLoader.current) {
      isUpdatedPageLoader.current = true;
      setPageLoaderStatus(100);
    }
  }, [setPageLoaderStatus]);
}
