import styled from "styled-components";
import Spacer from "@/common/Spacer/Spacer";
import Fan from "@/common/Icons/LandingPageFan";
import Circles from "@/common/Icons/LandingPageCircles";
import Text from "@/common/Text/Text";

export default function LandingPageContent() {
  return (
    <>
      <SectionContainer id="poke">
        <Section>
          <figure>
            <Circles height={528} width={532} />
          </figure>
          <SectionContent>
            <Text
              fontFamily="kotori"
              color="poke"
              fontSize="20px"
              fontWeight={700}
            >
              Proof of Knowledge &amp; Experience
            </Text>
            <Spacer size={8} axis="y" />
            <p>
              We created POKEs to be empowering, buildable parts of your work
              history, set as tokens. Once gifted or received, a POKE is yours
              alone.
            </p>
            <Spacer size={16} axis="y" />
            <p>
              Use POKEs as gifts or milestone achievements for friends, swap
              pokes with peers and support friends on completing projects or
              milestones. You can even accredit courses or classes you finished.
            </p>
          </SectionContent>
        </Section>
      </SectionContainer>
      <Spacer size={32} axis="y" />
      <SectionContainer>
        <SectionReversed>
          <figure>
            <Fan />
          </figure>
          <SectionContent>
            <Text
              fontFamily="kotori"
              color="poke"
              fontSize="20px"
              lineHeight={1.2}
              fontWeight={700}
            >
              Everyone can POKE
            </Text>
            <Spacer size={8} axis="y" />
            <p>
              Use POKEs as gifts or milestone achievements for friends, swap
              pokes with peers, and support friends in completing projects or
              milestones. You can even accredit courses or classes you&apos;ve
              finished! POKEs are endless in use.
            </p>
          </SectionContent>
        </SectionReversed>
      </SectionContainer>
      <Spacer size={32} axis="y" />
      <SectionContainer>
        <Section>
          <SectionContentCentered>
            <Text
              fontFamily="kotori"
              color="poke"
              textAlign="center"
              fontSize="20px"
              fontWeight={700}
            >
              Frequently Asked Questions
            </Text>
            <Spacer size={16} axis="y" />
            {FAQS.map((faq, i) => (
              <div key={i}>
                <Text fontFamily="kotori" fontSize="18px" fontWeight={700}>
                  {faq.question}
                </Text>
                <Spacer size={8} axis="y" />
                <FAQAnswer>{faq.answer}</FAQAnswer>
                <Spacer size={32} axis="y" />
              </div>
            ))}
          </SectionContentCentered>
        </Section>
      </SectionContainer>
      <Spacer size={50} axis="y" />
      <FooterContainer>
        <FooterOrb>
          <Text color="poke" fontFamily="kotori">
            &copy; {new Date().getFullYear()} Poke Co
          </Text>
        </FooterOrb>
      </FooterContainer>
    </>
  );
}

const FAQS = [
  {
    question: "What is a POKE?",
    answer:
      "POKE stands for Proof of Knowledge and Experience. The POKE protocol allows people to create and receive non-transferable NFTs as credentials, helping the future of work build their on-chain resume. POKEs can represent the completion of a course, graduating from school, endorsements from peers, contribution to a community, and so much more.",
  },
  {
    question:
      "Why use a POKE? Why are POKEs better than traditional systems like PDF certificates, LinkedIn endorsements, or just a pat on the back?",
    answer:
      "POKEs are better than traditional systems because they’re peer-reviewed, verified, and don’t require a third party to verify their validity. POKEs are a public record of your accomplishments, credentials, and contributions, and they go wherever you go. When you see a POKE, you know it’s legit because the evidence for the proof of knowledge and experience is stored on the blockchain!",
  },
  {
    question:
      "Okay, I get why POKEs are important. But why do we need a blockchain to do this?",
    answer:
      "When you see a POKE, you know it’s legit. In order for POKEs to be permanent, verified, and valid, it has to be submitted to and stored on the blockchain, which is a public ledger that anyone can read and edit (assuming the rest of the community agrees on those edits). If a bad actor creates or claims a faulty POKE, the community can easily call it out and show that the actor and POKE are fraudulent based on their wallet and publicly available transaction history.",
  },
  {
    question: "Is this free? Who pays for gas? What is even gas?",
    answer:
      "We use the Polygon blockchain because of the fast transactions and very low fees as compared to Ethereum. The low fees make it possible for POKE to cover your gas fee expenses, so using POKE is completely free!",
  },
  {
    question: "This is awesome, what can I do to help or support?",
    answer: "Feel free to leave us a note at hello@poke.so",
  },
];

const FAQAnswer = styled.p`
  padding-left: 10px;
  border-left: 2px solid var(--iris-800);
`;

const SectionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 826px) {
    justify-content: center;
  }
`;

const Section = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 25px 0 40px;

  figure {
    width: 100%;
    height: 100%;
    max-width: 528px;
    max-height: 532px;
  }

  @media (max-width: 826px) {
    align-items: center;
    flex-direction: column;

    figure {
      width: 50%;
      height: 50%;
      margin-bottom: 48px;
    }
  }
`;

const SectionReversed = styled(Section)`
  flex-direction: row-reverse;

  figure {
    max-width: 438px;
    max-height: 468px;
  }

  @media (max-width: 826px) {
    align-items: center;
    flex-direction: column;
  }
`;

const SectionContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  max-width: 475px;
`;

const SectionContentCentered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  max-width: 70ch;
  margin: auto;
`;

const FooterContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 100%;
  background-color: #f9ffef;
  overflow: hidden;
`;

const FooterOrb = styled.div`
  width: 400px;
  height: 200px;
  border-top-left-radius: 400px;
  border-top-right-radius: 400px;
  background: linear-gradient(180deg, #f0f9c9 0%, #8de1d9 146.94%);

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 826px) {
    width: 300px;
    height: 150px;
  }
`;
