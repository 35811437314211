import LoadingBar from "react-top-loading-bar";

import { useAppContext } from "@/state/Context";

export default function LoadingBarContainer() {
  const { pageLoaderStatus, setPageLoaderStatus } = useAppContext();

  return (
    <LoadingBar
      color="#5d5fef"
      progress={pageLoaderStatus}
      onLoaderFinished={() => setPageLoaderStatus(0)}
    />
  );
}
