import { useEffect, useState } from "react";
import { useSpring } from "react-spring";

interface IProps {
  translateX?: string;
  translateY?: string;
}

export default function useAnimation({
  translateX = "0",
  translateY = "0",
}: IProps = {}) {
  const [shouldMove, setShouldMove] = useState(false);

  const style = useSpring({
    transform: shouldMove
      ? `translate(${translateX}px, ${translateY}px)`
      : "translate(0px, 0px)",
    config: {
      tension: 170,
      friction: 8,
    },
  });

  const onMouseEnter = () => {
    if (!shouldMove) {
      setShouldMove(true);
    }
  };

  useEffect(() => {
    const reset = () => {
      if (shouldMove) {
        setShouldMove(false);
      }
    };

    const timeout = setTimeout(reset, 200);
    return () => {
      clearTimeout(timeout);
    };
  }, [shouldMove]);

  return { style, onMouseEnter };
}
