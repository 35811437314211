export default function Fan() {
  return (
    <svg viewBox="0 0 438 468" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M219.014 419.502C219.014 328.725 292.555 255.148 383.257 255.148C402.373 255.148 420.727 258.436 437.792 264.442C437.917 261.363 438 258.269 438 255.148C438 134.116 339.95 36 219 36C98.0496 36 0 134.116 0 255.148C0 258.255 0.0831666 261.363 0.207929 264.442C17.2727 258.436 35.6267 255.148 54.7431 255.148C145.459 255.148 218.986 328.739 218.986 419.502H219.014Z"
        fill="url(#paint0_linear_32_594)"
      />
      <path
        d="M219.009 420C219.009 360.587 267.029 312.431 326.255 312.431C338.737 312.431 350.722 314.583 361.864 318.514C361.946 316.499 362 314.474 362 312.431C362 233.216 297.977 169 219 169C140.023 169 76 233.216 76 312.431C76 314.465 76.0543 316.499 76.1358 318.514C87.2785 314.583 99.2631 312.431 111.745 312.431C170.98 312.431 218.991 360.596 218.991 420H219.009Z"
        fill="url(#paint1_linear_32_594)"
      />
      <path
        d="M219.005 420C219.005 387.808 244.862 361.716 276.752 361.716C283.474 361.716 289.927 362.882 295.927 365.012C295.971 363.92 296 362.823 296 361.716C296 318.794 261.526 284 219 284C176.474 284 142 318.794 142 361.716C142 362.818 142.029 363.92 142.073 365.012C148.073 362.882 154.526 361.716 161.248 361.716C193.143 361.716 218.995 387.813 218.995 420H219.005Z"
        fill="url(#paint2_linear_32_594)"
      />
      <g filter="url(#filter0_d_32_594)">
        <rect x="200" y="10" width="38" height="436" rx="19" fill="#F0F9C9" />
      </g>
      <defs>
        <filter
          id="filter0_d_32_594"
          x="184"
          y="0"
          width="70"
          height="468"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="6" />
          <feGaussianBlur stdDeviation="8" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.592157 0 0 0 0 0.894118 0 0 0 0 0.847059 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_32_594"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_32_594"
            result="shape"
          />
        </filter>
        <linearGradient
          id="paint0_linear_32_594"
          x1="219"
          y1="36"
          x2="219"
          y2="419.502"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0F9C9" />
          <stop offset="1" stopColor="#8DE1D9" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_32_594"
          x1="219"
          y1="169"
          x2="219"
          y2="420"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0F9C9" />
          <stop offset="1" stopColor="#8DE1D9" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_32_594"
          x1="219"
          y1="284"
          x2="219"
          y2="420"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F0F9C9" />
          <stop offset="1" stopColor="#8DE1D9" />
        </linearGradient>
      </defs>
    </svg>
  );
}
